<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="irsaliye"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Firmamız İrsaliyeleri"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>

            
        </v-main>

    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var sal_kalem_detay = {
        tableName: 'sal_kalem_detay',
        columns: {
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_ItemName',
                edit: '',
                width: 80
            },
            miktarsiz: {
                type: 'hidden',
                name: 'miktarsiz',
            },
            talepmiktar:
            {
                type: 'number',
                label: 'FL_AmountSent',
                edit: 'S',
                name: 'talepmiktar',
                width: 20
            },
            gelenmiktar: {
                type: 'number',
                label: 'FL_AmountBilled',
                edit: '',
                name: 'gelenmiktar',
                width: 20
            },
            talepbirim: {
                type: 'text',
                label: 'FL_Unit',
                edit: '',
                name: 'talepbirim',
                width: 20
            },
            alisfiyat: {
                type: 'number',
                label: 'FL_UnitPrice',
                edit: '',
                name: 'alisfiyat',
                width: 20
            },
            doviz: {
                type: 'text',
                label: 'FL_Currency',
                edit: '',
                name: 'doviz',
                width: 20
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },

        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                miktar: { items: [], error: false, readonly: false, hidden: false },
                talepbirim: { items: [], error: false, readonly: false, hidden: false },
                gelenmiktar: { items: [], error: false, readonly: false, hidden: false },
            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'irsaliye_kalemler'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };


    var Irsaliye_formInfo = {
        tabs: [
            { order: 1, header: 'FN_Waybill' },

        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_AcceptanceInformation'

            },
            {
                tab: 1,
                order: 3,
                header: 'TH_ItemInformation'
            },
            {
                tab: 1,
                order: 4,
                header: 'FL_AdditionalFiles'
            },
            {
                tab: 1,
                order: 5,
                header: 'FL_Notes'
            },
        ],

        Columns: {
            id: {
                type: 'text',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },
            durum: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'durum',
                label: 'FL_Status',
                edit: '',
                col: '6',
            },
            yaratan : {
                type: 'text',
                group: 1,
                sira: 20,
                name: 'yaratan',
                label: 'FL_Receiver',
                edit: '',
                col: '6',
            },
            tarih:
            {
                type: 'date',
                group: 1,
                sira: 30,
                name: 'tarih',
                label: 'FL_Date',
                edit: '01',
                col: '6',
            },
            TedarikciKullanicisiAdSoyad:
            {
                type: 'text',
                group: 1,
                sira: 35,
                name: 'TedarikciKullanicisiAdSoyad',
                label: 'FL_Creater',
                edit: '',
                col: '6',
            },
            duzenlemetarihi: {
                type: 'date',
                group: 2,
                sira: 10,
                name: 'duzenlemetarihi',
                label: 'FL_ArrangementDate',
                edit: 'S',
                col: '6',
            },
            siparisno: {
                type: 'text',
                group: 2,
                sira: 20,
                name: 'siparisno',
                label: 'FL_OrderNo',
                edit: '',
                col: '6',
            },
            sevktarihi: {
                type: 'date',
                group: 2,
                sira: 25,
                name: 'sevktarihi',
                label: 'FL_ShipmentDate',
                edit: 'S',
                col: '6',
            },
            aciklama: {
                type: 'text',
                group: 2,
                sira: 30,
                name: 'aciklama',
                label: 'FL_Comment',
                edit: '',
                col: '6',
            },
            irsaliyenushasi: {
                type: 'file',
                group: 4,
                sira: 10,
                name: 'irsaliyenushasi',
                label: 'FL_WaybillCopy',
                edit: 'S',
                col: '6',
            },
            tedarikciek1file:
            {
                type: 'file',
                name: 'tedarikciek1file',
                label: 'FL_AdditionalFiles',
                group: 4,
                sira: 25,
                col: '6',
                edit: 'S',
            },
            irsaliyeno: {
                type: 'text',
                group: 2,
                sira: 15,
                name: 'irsaliyeno',
                label: 'FL_WaybillNo',
                edit: 'S',
                col: '6',
            },
            sal_kalem_detay: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'sal_kalem_detay',
                label: 'TH_ShipmentItems',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true,
            },
            oonaynot: {
                type: 'history',
                group: 5,
                sira: 25,
                name: 'oonaynot',
                col: '12',
                edit: 'ST',
                label: ''
            },
        },
        form: 'irsaliye',
        formid: 0,
        viewName: 'benim',
        viewparameter: '',
        files: ['irsaliyenushasi', 'tedarikciek1file'],
        extdata: [sal_kalem_detay],
        actions: [],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    export default {
        name: 'irsaliye',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true }
        },
        data: function () {
            return {
                valid: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                actionResultDialog: false,
                validateError: '',
                username: '',
                form: '',
                formname: 'irsaliye',
                openallpanel: [0],
                notification: false,
                currentform: 'irsaliye',
                errors: []
            };
        },
        methods: {
            FormLoaded: function () {
                var currstate = this.GetFormState;

                if ('TS'.indexOf(currstate) != -1 && !this.actionBarWait) {
                    if ('S'.indexOf(currstate) != -1 && !this.actionBarWait) {
                        this.$store.commit('SetFormActions', [{ label: 'AL_CancelForm', action: 'Iptal', type: 'external' }, { label: 'AL_Complete', action: 'Tamam', type: 'external' }]);
                    }
                    if ('T'.indexOf(currstate) != -1 && !this.actionBarWait) {
                        this.$store.commit('SetFormActions', [{ label: 'AL_Withdraw', action: 'Gericek', type: 'external' }]);
                    }
                }
                else {
                    this.$store.commit('SetFormActions', []);
                }
            },
            NeedApprove: function (action) {
                var res;
                switch (action) {
                    case "Tamam":
                        res = '';
                        break;
                    case "Gericek":
                        res = this.$root.$i18n.t('DM_RejectionConfirmation');
                        break;
                    case "Iptal":
                        res = this.$root.$i18n.t('DM_CancelForm');
                        break;
                    default:
                        res = '';
                }
                return res;
            },
            Selected: function ()
            {
                return;
            },


            FormButtonClicked(col) {
                var name = col.name;
                //if (name == 'ShowRequest') {
                //    //Request formunu göster
                //    var url = '/formart/saltalep/saltalep_frm.aspx?openformid=' + this.GetFormField('RequestId');
                //    window.open(url);
                //}
            },

            RowClicked: function (rowdata, table) {
                return;
            },

            RowButtonClicked: function (payload, tableName) {
                return;
            },

            ChangeAttribute: function (attribute, tableName) {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
            ValidateForm(action) {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";

                var formdata = this.GetFormData;

                if (action.action == "Gericek" || action.action == "Iptal") {
                    if (!formdata.oonaynot) {
                        var err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        this.errors.push(err);
                        this.SetFieldError("oonaynot");
                    }
                }
                if (this.errors.length > 0) {
                    result = this.errors;
                }
                return result;
            },
        },

        mounted: function () {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', Irsaliye_formInfo);
            var form = Irsaliye_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form') {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined && Number(params.id) > 0) {
                    var formid = Number(params.id);

                    if (formid != undefined && formid != 0) {
                        this.$store.commit('SetFormAndFormId', {
                            form: form,
                            formid: formid
                        });
                        this.LoadFormData(formid);
                    }
                }
            }
            else if (whatToShow == 'yeni') {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo => {
                self.username = userInfo.data.AdSoyad;
            });
        },
        computed: {
            FormAttributes: function () {
                return this.GetFormAttributes;
            },
            UserName() {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function () {
                    return this.GetFormLoadingStatus;
                }
            },

        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal) {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },

            $route: function (nval, oval) {
                console.log('WATCH Route irsaliye :', nval, ' form:', this.formname);
                console.log('WATCH Route irsaliye this:', this.$route);
                var form = Irsaliye_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form') {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined) {
                        if (Number(params.id) > 0) {

                            var formid = Number(params.id);

                            if (formid != undefined && formid != 0) {
                                this.$store.commit('SetFormAndFormId', {
                                    form: form,
                                    formid: formid
                                });
                                this.LoadFormData(formid);
                            }
                        }
                        else {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: 0
                            });
                            this.LoadFormData(0);
                        }
                    }
                }
                else if (whatToShow == 'yeni') {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },

            notification: function (n, o) {
                this.$emit("ChangeNotifiction",
                    { validateError: this.validateError, notification: this.notification });
            },

            validateError: function (n, o) {
                this.$emit("ChangeNotifiction",
                    { validateError: this.validateError, notification: this.notification });
            }
        }
    };
</script>
